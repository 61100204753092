
"use client"
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { getCartQnty } from "components/applications/cart/helpers/Cart.helpers";
import dynamic from 'next/dynamic';
import IconButton from './IconButton';
import { useQuery } from 'react-query';
import GetCart from 'services/bigcommerce/GetCart';

const InlineButton = dynamic(() => import('./InlineButton'))


function MyCartBtn({ 
    isMobile, 
    user, 
    type = "", 
    className, 
    textClass, 
    iconClass,
    isImmobile,
    Style
}){
    const [ qty, setQty ] = useState(0);

    const { data: cartData } = useQuery({
        queryKey: ["cart"],
        queryFn: () => GetCart(),
    })

    useEffect(() => {
        if( cartData?.line_items?.physical_items || cartData?.line_items?.custom_items ){
            const cartQty = getCartQnty(cartData)
            setQty(cartQty)
        }

    }, [ cartData, user ])



    return(
        <>
            {type === "flyout" ? 
                <InlineButton 
                    {...{ iconClass, className, textClass, qty, Style, id: cartData?.id }} 
                />
            :
                <IconButton  
                    {...{ qty, isMobile, isImmobile, Style, id: cartData?.id }}
                />
            }
        </>
    )
}


export default MyCartBtn;